<template>
    <user-dashboard-layout>
        <v-row>
            <v-col cols="auto">
                <v-card class="pa-2 d-flex justify-center">
                    <v-date-picker v-model="selectedDate"
                                   :allowed-dates="allowedDates"
                    ></v-date-picker>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="auto">
                <v-btn @click="save" color="success"
                       class="my-3"
                       :disabled="!selectedDate"
                >Add new
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="auto">
                <v-data-table
                    v-if="holidays.length > 0"
                    :loading="loading"
                    disable-sort
                    :headers="headers"
                    :items="holidays"
                >
                    <template v-slot:item.actions="{ item }">
                        <v-icon small @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5"
                >{{ $t('general.are-you-sure-you-want') }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDelete"
                    >{{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                    >{{ $t('general.ok') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    components: {UserDashboardLayout},
    data: () => ({
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: "Date",
                value: "date",
            },
            {
                text: "Actions",
                value: "actions",
                width: 120,
                align: 'center'
            }
        ],
        editedIndex: -1,
        loading: false,
        selectedDate: ''
    }),
    computed: {
        ...mapGetters(["defaultHoliday", "holiday", "holidays"]),
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        }
    },
    methods: {
        ...mapActions([
            "storeHoliday",
            "setHolidays",
            "updateHoliday",
            "destroyHoliday"
        ]),
        ...mapMutations(["setHoliday"]),
        async init() {
            this.loading = true;
            this.selectedDate = ''
            await this.setHolidays()
            this.loading = false;
        },
        deleteItem(item) {
            if (this.holiday.isArray) {
                this.editedIndex = this.holiday.findIndex(
                    i => i.id === item.id
                )
            } else {
                this.editedIndex = [this.holiday].findIndex(
                    i => i.id === item.id
                )
            }

            this.setHoliday(item);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            await this.destroyHoliday(this.holiday);
            await this.init();
            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.setHoliday(this.defaultHoliday);
                this.editedIndex = -1;
            });
            this.holiday.date = ''
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.setHoliday(this.defaultHoliday);
                this.editedIndex = -1;
            });
        },
        async save() {
            this.holiday.date = this.selectedDate
            if (this.editedIndex > -1) {
                await this.updateHoliday(this.holiday);
            } else {
                await this.storeHoliday(this.holiday);
            }
            await this.init();
            this.close();
        },
        allowedDates(val) {
            for (let holiday of this.holidays) {
                if (holiday.date === val) {
                    return false
                }
            }
            return val
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
};
</script>

<style>
.v-data-table .v-data-table-header {
    display: none
}
</style>
